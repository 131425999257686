import { Inter } from 'next/font/google';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { Layout, CustomHead } from '@/components/commons';
import React from 'react';
import { NextPageWithLayout } from '@/pages/_app';
import { getUserPublicWebFrontpageSetting } from '@/lib/services/zhuye';
import HomeContent from '@/components/home/HomeContent';

const inter = Inter({ subsets: ['latin'] });

const HomePage: NextPageWithLayout<InferGetServerSidePropsType<typeof getServerSideProps>> = () => {
  return <HomeContent inter={inter} />;
};

//方式 1
export async function getServerSideProps({ locale }: GetServerSidePropsContext) {
  let data: any = {};
  try {
    data = await getUserPublicWebFrontpageSetting();
  } catch {
    console.log('');
  }

  return {
    props: {
      data: data?.data || {},
      messages: (await import(`@/locales/${locale}.json`)).default,
    },
  };
}

HomePage.getLayout = (page: React.ReactElement) => {
  const { data } = page.props;
  return (
    <>
      {data ? <CustomHead title={data.title} description={data.description} keywords={data.keywords} /> : ''}
      <Layout h5HaveHeader={true}>{page}</Layout>
    </>
  );
};

export default HomePage;
